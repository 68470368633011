import React, { useEffect, useState } from "react";
import { IoReturnUpBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import styles from "./ProjectPage.module.css";
import { MdArrowOutward } from "react-icons/md";
import {
  getBlogContent,
  getCreators,
  getDate,
  getHeader,
  getLinks,
  getTechnologies,
  ProjectIDs,
} from "../helpers/blogHelpers";
import Footer from "./Footer";

const openNewTab = (url) => {
  window.open(url, "_blank").focus();
};

const ProjectPage = ({ id }) => {
  const navigate = useNavigate();
  const [views, setViews] = useState(null);

  const links = getLinks(id);
  const content = getBlogContent(id);
  const creators = getCreators(id);
  const technology = getTechnologies(id);
  const date = getDate(id);
  const headerData = getHeader(id);

  const isCloud = id === ProjectIDs.CLOUDRESUME;

  useEffect(() => {
    const getViews = async () => {
      const response = await fetch(
        "https://7bz27dvtfqoffwsawijz7kthme0ygycc.lambda-url.ca-central-1.on.aws/"
      );
      const data = await response.json();
      setViews(data);
    };
    getViews();
  }, []);

  return (
    <div>
      <header className="navBack my-4 w-fit" onClick={() => navigate("/")}>
        <li className="px-4 py-3 cursor-pointer hover:bg-neutral-100 w-fit duration-300">
          <IoReturnUpBack size={25} />
        </li>
      </header>

      <div className="mx-auto w-[90%] mt-8 border-neutral-300 border-[1px] border-b-0 grid grid-cols-12">
        <div className="hidden md:block col-start-1 col-end-3">
          {creators && date && (
            <div className="hidden md:block  border-b-[1px] border-neutral-300 py-8 px-4">
              <div className="text-md text-neutral-600">Creator(s)</div>
              <div className="text-lg font-medium">{creators}</div>
              <div className="text-md mt-4">Created on</div>
              <div className="text-lg font-medium">{date}</div>
            </div>
          )}
          {links && (
            <div className="hidden md:block text-lg py-8 px-4 border-b-[1px] border-neutral-300">
              {links.codeLink && (
                <div
                  className="underline mb-2 cursor-pointer flex w-fit"
                  onClick={() => openNewTab(links.codeLink)}
                >
                  Project Code
                  <div className="m-auto px-2">
                    <MdArrowOutward />
                  </div>
                </div>
              )}
              {links.liveLink && (
                <div
                  className="underline cursor-pointer flex  w-fit"
                  onClick={() => openNewTab(links.liveLink)}
                >
                  Project Live
                  <div className="m-auto px-2">
                    <MdArrowOutward />
                  </div>
                </div>
              )}
            </div>
          )}
          {technology && (
            <div className="hidden md:block border-b-[1px] border-neutral-300 py-8 px-4">
              <div className="text-md text-neutral-600">Technologies</div>
              <div className="text-lg font-medium">{technology}</div>
            </div>
          )}
          {isCloud && (
            <div className="hidden md:block border-b-[1px] border-neutral-300 py-8 px-4">
              <div className="text-md text-neutral-600">Visit Counter</div>
              <div className="text-lg font-medium">
                {views !== null ? views : "Loading..."}
              </div>
            </div>
          )}
        </div>

        {headerData && (
          <div className="col-start-1 col-end-13 md:col-start-3 md:col-end-13 border-b-[1px] border-l-[1px] border-neutral-300">
            <div className="py-12 px-20">
              <div className="text-md uppercase text-neutral-600">
                {headerData.subtext}
              </div>
              <div className="text-5xl font-semibold mt-2">
                {headerData.title}
              </div>
            </div>
            <img className="w-full object-contain" src={headerData.img} />
          </div>
        )}
        <div className="col-start-2 col-end-12 md:col-start-3 md:col-end-11 border-l-[1px] border-r-[1px] border-neutral-300 px-16 pb-16">
          <div className={styles.contentContainer}>{content}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProjectPage;
