import {
  ApryseContent,
  CloudContent,
  D3Content,
  LonelinessContent,
  SignatureContent,
  VectorContent,
} from "../content/content";

import signature from "../assets/signature.png";
import lonely from "../assets/lonelydemo.png";

import raster from "../assets/raster.jpeg";

import datavis from "../assets/d3passion/vismask.png";
import apryse from "../assets/apryseUI.png";

import aws from "../assets/awsdiagram.png";

export const ProjectIDs = Object.freeze({
  LONELINESS: "loneliness",
  SIGNATURES: "signatures",
  VECTOR: "vector",
  D3: "d3",
  APRYSEUI: "apryse",
  CLOUDRESUME: "cloudresume",
});

export const getHeader = (id) => {
  if (id === ProjectIDs.LONELINESS) {
    return {
      subtext: "data vis",
      title: "Storytelling Loneliness",
      img: lonely,
    };
  }
  if (id === ProjectIDs.SIGNATURES) {
    return {
      subtext: "api",
      title: "Signature Styles",
      img: signature,
    };
  }

  if (id === ProjectIDs.VECTOR) {
    return {
      subtext: "api",
      title: "Creating Vector Appearances",
      img: raster,
    };
  }
  if (id === ProjectIDs.D3) {
    return {
      subtext: "data vis",
      title: "Experimenting with D3",
      img: datavis,
    };
  }
  if (id === ProjectIDs.APRYSEUI) {
    return {
      subtext: "ui",
      title: "Apryse Responsive Design",
      img: apryse,
    };
  }

  if (id === ProjectIDs.CLOUDRESUME) {
    return {
      subtext: "cloud",
      title: "AWS Cloud Resume Challenge",
      img: aws,
    };
  }

  return {
    subtext: "data vis",
    title: "Storytelling Loneliness",
    img: lonely,
  };
};

export const getBlogContent = (id) => {
  if (id === ProjectIDs.LONELINESS) {
    return <LonelinessContent />;
  }
  if (id === ProjectIDs.SIGNATURES) {
    return <SignatureContent />;
  }
  if (id === ProjectIDs.VECTOR) {
    return <VectorContent />;
  }
  if (id === ProjectIDs.D3) {
    return <D3Content />;
  }
  if (id === ProjectIDs.APRYSEUI) {
    return <ApryseContent />;
  }
  if (id === ProjectIDs.CLOUDRESUME) {
    return <CloudContent />;
  }
};

export const getLinks = (id) => {
  if (id === ProjectIDs.LONELINESS) {
    return {
      codeLink: "https://github.com/ajo01/loneliness",
      liveLink:
        "https://www.students.cs.ubc.ca/~cs-447/23Sep/fame/projects/g15/index.html",
    };
  }
  if (id === ProjectIDs.SIGNATURES) {
    return {
      codeLink: "https://github.com/ApryseSDK/webviewer-ui",
      liveLink: "https://showcase.apryse.com/",
    };
  }
  if (id === ProjectIDs.VECTOR) {
    return {
      codeLink: "https://www.npmjs.com/package/@pdftron/canvas-to-pdf",
    };
  }
  if (id === ProjectIDs.D3) {
    return {
      codeLink: "https://github.com/ajo01",
    };
  }
  if (id === ProjectIDs.APRYSEUI) {
    return {
      liveLink: "https://apryse.com/",
    };
  }
  if (id === ProjectIDs.CLOUDRESUME) {
    return {
      codeLink: "https://github.com/ajo01/cloud-resume-challenge",
    };
  }
};

export const getCreators = (id) => {
  if (id === ProjectIDs.LONELINESS) {
    return (
      <div>
        <div>Amy Jo</div>
        <div>Jasmine Ke</div>
        <div> Jorene Ng</div>
        <div> Poh Leanne Kee</div>
      </div>
    );
  }
  if (id === ProjectIDs.APRYSEUI) {
    <div>
      <div>Amy Jo</div>
      <div>+ 3 other devs</div>
    </div>;
  }
  return <div>Amy Jo</div>;
};

export const getDate = (id) => {
  if (id === ProjectIDs.LONELINESS) {
    return "Sep 2023";
  }
  if (id === ProjectIDs.SIGNATURES) {
    return "Sep 2022";
  }

  if (id === ProjectIDs.VECTOR) {
    return "June 2022";
  }
  if (id === ProjectIDs.D3) {
    return "Jan 2024 - Present";
  }
  if (id === ProjectIDs.APRYSEUI) {
    return "Sep 2023 - Dec 2023";
  }
  if (id === ProjectIDs.CLOUDRESUME) {
    return "Aug 2024 - Present";
  }
};

export const getTechnologies = (id) => {
  if (id === ProjectIDs.LONELINESS) {
    return (
      <div>
        <div>React</div>
        <div>D3</div>
        <div>JavaScript</div>
        <div> Python</div>
        <div>Figma</div>
      </div>
    );
  }
  if (id === ProjectIDs.SIGNATURES) {
    return (
      <div>
        <div>React</div>
        <div>JavaScript</div>
        <div>Algorithms</div>
      </div>
    );
  }
  if (id === ProjectIDs.VECTOR) {
    return (
      <div>
        <div>JavaScript</div>
        <div>Data Structures</div>
        <div>Open Source</div>
        <div>Automated Testing</div>
      </div>
    );
  }
  if (id === ProjectIDs.D3) {
    return (
      <div>
        <div>D3.js</div>
        <div>Svelte</div>
        <div>Figma</div>
      </div>
    );
  }
  if (id === ProjectIDs.APRYSEUI) {
    return (
      <div>
        <div>Next.js</div>
        <div>TailwindCSS</div>
        <div>Sanity</div>
        <div>TypeScript</div>
      </div>
    );
  }

  if (id === ProjectIDs.CLOUDRESUME) {
    return (
      <div>
        <div>S3</div>
        <div>Cloudfront</div>
        <div>Route53</div>
        <div>DynamoDB</div>
        <div>Lambda</div>
        <div>ACM</div>
        <div>IAM</div>
        <div>Terraform</div>
      </div>
    );
  }
};
