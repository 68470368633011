import React from "react";

const OneExperience = ({ company, title }) => (
  <div className="p-16 flex border-b-2 border-neutral-200 text-neutral-700 text-left">
    <div className="text-xl md:text-3xl mr-16 md:mr-32 w-1/5 leading-10 m-auto font-medium enlarge">
      {company}
    </div>
    <div className="text-xl w-4/5">
      <div className="m-auto enlarge">{title}</div>
    </div>
  </div>
);

const exps = [
  {
    company: "UBC",
    title: "Fullstack Engineer",
  },
  {
    company: "nwPlus",
    title: "Software Developer / Project Lead",
  },
  {
    company: "Apryse",
    title: "Web Developer",
  },
  {
    company: "Jostle",
    title: "Frontend Developer",
  },
];

const Experience = ({ expRef }) => {
  return (
    <div className="w-full py-24 my-16 text-left gradientBorder" ref={expRef}>
      <div className="text-5xl md:text-6xl pl-16">Experience</div>
      <div className="text-xl pl-16 mt-8">My roles over the years</div>
      <div className="ml-8 lg:ml-36 my-16 border-t-2 border-neutral-200">
        {exps.map(({ company, title }, i) => (
          <OneExperience key={i} company={company} title={title} />
        ))}
      </div>
    </div>
  );
};

export default Experience;
