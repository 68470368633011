import React from "react";
import styles from "./Spinner.module.css";

const Spinner = () => {
  return (
    <div className="h-[100vh] w-full flex">
      <span class={styles.loader}></span>
    </div>
  );
};

export default Spinner;
