import React from "react";
import { SocialIcon } from "react-social-icons";
import { motion } from "framer-motion";

const Landing = ({ aboutRef, skillsRef, projectRef, expRef }) => {
  const executeScroll = (ref) => ref.current.scrollIntoView();

  return (
    <div className="flex w-full h-[100vh]">
      <header className="nav enlarge cursor-pointer">
        <li data-hover onClick={() => executeScroll(aboutRef)}>
          about
        </li>
        <li data-hover onClick={() => executeScroll(expRef)}>
          experience
        </li>
        <li data-hover onClick={() => executeScroll(skillsRef)}>
          skills
        </li>
        <li data-hover onClick={() => executeScroll(projectRef)}>
          projects
        </li>
      </header>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.5,
          delay: 0.2,
        }}
        className="m-auto"
      >
        <div className="m-auto text-xl text-neutral-600">Developer</div>
        <div className="m-auto text-6xl sm:text-7xl md:text-8xl pt-8 text-black enlarge">
          Amy Jo
        </div>
        <div className="flex m-auto justify-center pt-16 mb-4">
          <SocialIcon
            className="m-2"
            bgColor="black"
            url="https://www.linkedin.com/in/amyjo1/"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="LinkedIn"
          />
          <SocialIcon
            bgColor="black"
            className="m-2"
            url="https://github.com/ajo01"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="GitHub"
          />
          <SocialIcon
            className="m-2"
            bgColor="black"
            url="mailto:amyjo0605@gmail.com"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Email"
          />
        </div>
      </motion.div>
    </div>
  );
};

export default Landing;
