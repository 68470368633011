import React, { useState, useCallback } from "react";
import BlobComponent from "./BlobComponent"; // Adjust the import as needed

const GrayBlob = (x, y, canvasWidth, canvasHeight) => {
  return (
    <div className="blob scale-50 enlarge">
      <div
        className="top-[-50px] right-[-180px] absolute"
        style={{
          transform: `translate(${(0.5 - x / canvasWidth) * 80}px, ${
            (0.5 - y / canvasHeight) * 120
          }px)`,
        }}
      >
        <svg
          width="899"
          height="528"
          viewBox="0 0 899 528"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M432.93 0.0837278C623.333 -2.76367 782.236 67.3424 847.729 160.227C922.684 266.53 930.589 393.5 760.052 464.02C568.527 543.219 283.77 552.633 95.8346 471.148C-67.3579 400.391 11.6189 275.108 85.6734 171.623C147.198 85.6474 256.377 2.724 432.93 0.0837278Z"
            fill="#F4F3F3"
          />
        </svg>
      </div>
    </div>
  );
};

const CanvasArt = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (event) => {
    const canvas = event.currentTarget;
    const rect = canvas.getBoundingClientRect();

    const handleMouseMove = (e) => {
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      setCursorPosition({ x, y });
    };

    canvas.addEventListener("mousemove", handleMouseMove);

    return () => {
      canvas.removeEventListener("mousemove", handleMouseMove);
    };
  };

  const handleMouseLeave = useCallback((event) => {
    setCursorPosition({ x: 0, y: 0 });
  }, []);

  const { x, y } = cursorPosition;
  const canvasWidth = window.innerWidth;
  const canvasHeight = window.innerHeight;

  return (
    <div
      className="canvas-art w-full md:w-3/4 h-[350px] m-auto relative overflow-hidden"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="relative top-12 left-16">
        {GrayBlob(x, y, canvasWidth, canvasHeight)}
        <div
          className="blob blob1 right-[23px] absolute top-[-80px] rotate-90 enlarge"
          style={{
            transform: `translate(${(0.5 - x / canvasWidth) * 100}px, ${
              (0.5 - y / canvasHeight) * 100
            }px)`,
          }}
        ></div>
        <BlobComponent
          classStyle={{
            transform: `translate(${(0.5 - x / canvasWidth) * 150}px, ${
              (0.5 - y / canvasHeight) * 50
            }px)`,
          }}
        />
      </div>
    </div>
  );
};

export default CanvasArt;
