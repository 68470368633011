import React from "react";
import Skill from "./Skill";
import "./Skills.css";

const skills = [
  {
    skill: "Web",
    text: "API design, performance optimization, custom tooling, databases, open source development",
  },

  {
    skill: "UI/UX",
    text: "Interaction design, accessibility, Canvas/SVG animations, beautiful and intuitive interfaces",
  },
  {
    skill: "Data Visualization",
    text: "Data cleaning, interactive design, storytelling, customized charts from scratch",
  },
  {
    skill: "DevOps",
    text: "Serverless design, infrastructure as code, containerization, CI/CD workflows",
  },
];

const Skills = ({ skillsRef }) => {
  return (
    <div
      className="w-full py-24 my-16 text-left gradientBorder"
      ref={skillsRef}
    >
      <div className="text-5xl md:text-6xl pl-16">Skills</div>
      <div className="text-xl pl-16 mt-8">
        I've worked with a variety of technologies across different industries
      </div>
      <div className="ml-8 lg:ml-36 my-16 border-t-2 border-neutral-200">
        {skills.map(({ skill, text }, i) => (
          <Skill key={i} skill={skill} text={text} />
        ))}
      </div>
    </div>
  );
};

export default Skills;
