import React from "react";
import styles from "./Blob.module.css";

const BlobComponent = ({ classStyle }) => {
  const className = styles.blob + " enlarge blob";
  return (
    <div className={className} style={classStyle}>
      <span className={styles.span1}></span>
      <span className={styles.span2}></span>
      <span className={styles.span3}></span>
    </div>
  );
};

export default BlobComponent;
