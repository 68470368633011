import React from "react";

const Skill = ({ skill, text }) => {
  return (
    <div className="p-16 border-b-2 border-neutral-200 flex text-neutral-600 text-left">
      <div className="text-2xl md:text-4xl mr-16 md:mr-32 w-2/5 leading-10 m-auto enlarge font-medium">
        {skill}
      </div>
      <div className="text-xl w-3/5">
        <div className="m-auto enlarge">{text}</div>
      </div>
    </div>
  );
};

export default Skill;
