import { useEffect } from "react";
import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  useParams,
} from "react-router-dom";
import Page from "./App";
import ProjectPage from "./component/ProjectPage";

const ProjectRouter = () => {
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  return <ProjectPage id={id} />;
};

function AppRouter() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Page />,
    },
    {
      path: "projects/:id",
      element: <ProjectRouter />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default AppRouter;
