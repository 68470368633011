import React from "react";

import { useNavigate } from "react-router-dom";
import styles from "./Project.module.css";

const Project = ({ text, type, img, link, active }) => {
  const navigate = useNavigate();
  const tooltipProps = active
    ? null
    : {
        ["data-tooltip-id"]: "my-tooltip",
        ["data-tooltip-content"]: "Coming soon",
      };

  return (
    <div
      className="w-full flex h-[210px] my-4 text-left cursor-pointer"
      onClick={() => (active ? navigate(link) : null)}
      {...tooltipProps}
    >
      <img
        src={img}
        alt={text}
        className={`${styles.img} w-[55%] object-cover saturate-0`}
      ></img>
      <div className="w-[45%] pl-12 m-auto ">
        <div className="py-4">
          <div className="text-neutral-500 text-xl">{type}</div>
          <div className="text-neutral text-xl font-semibold pt-2">{text}</div>
        </div>
      </div>
    </div>
  );
};

export default Project;
