import React, { useRef, useEffect, useState } from "react";

const CanvasComponent = () => {
  const canvasRef = useRef(null);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [radius, setRadius] = useState(30);
  const [targetRadius, setTargetRadius] = useState(30);
  const animationFrameRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const x = e.pageX - window.scrollX;
      const y = e.pageY - window.scrollY;
      setMousePos({ x, y });
    };

    const handleResize = () => {
      if (canvasRef.current) {
        canvasRef.current.width = window.innerWidth;
        canvasRef.current.height = window.innerHeight;
      }
    };

    const render = () => {
      if (canvasRef.current) {
        const ctx = canvasRef.current.getContext("2d");
        const width = canvasRef.current.width;
        const height = canvasRef.current.height;

        // Smooth radius animation
        setRadius((prevRadius) => {
          const diff = targetRadius - prevRadius;
          const newRadius = prevRadius + diff * 0.1; // Adjust speed here
          return Math.abs(diff) < 0.1 ? targetRadius : newRadius;
        });

        ctx.clearRect(0, 0, width, height);
        ctx.beginPath();
        ctx.arc(mousePos.x, mousePos.y, radius, 0, Math.PI * 2, false);
        ctx.fillStyle = "#ffffff";
        ctx.fill();
        ctx.closePath();

        animationFrameRef.current = requestAnimationFrame(render);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("mousemove", handleMouseMove);

    const handleMouseEnter = () => {
      setTargetRadius(80); // Desired enlarged radius
    };

    const handleMouseLeave = () => {
      setTargetRadius(30); // Original radius
    };

    const elems = document.querySelectorAll(".enlarge");
    elems.forEach((el) => {
      el.addEventListener("mouseenter", handleMouseEnter);
      el.addEventListener("mouseleave", handleMouseLeave);
    });

    render();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("mousemove", handleMouseMove);
      cancelAnimationFrame(animationFrameRef.current);
      elems.forEach((el) => {
        el.removeEventListener("mouseenter", handleMouseEnter);
        el.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, [mousePos, targetRadius]);

  return <canvas ref={canvasRef} className="canvas"></canvas>;
};

export default CanvasComponent;
