import React from "react";

const Footer = () => {
  return (
    <footer className="h-[200px] w-full bg-black ">
      <div className="text-center text-white text-lg pt-[100px]">
        <div> Made by Amy Jo</div>
      </div>
    </footer>
  );
};

export default Footer;
