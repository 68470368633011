import dot from "../assets/dot.png";
import bar from "../assets/bar.png";
import sankey from "../assets/sankey.png";
import scatter from "../assets/scatter.png";
import longDemo from "../assets/longlonelydemo.gif";

import { CopyBlock } from "react-code-blocks";

import signatureDemo from "../assets/signaturedemo.gif";
import smallpdf from "../assets/smallpdf.gif";

import adobespec from "../assets/vector/adobespec.png";
import compare from "../assets/vector/comparechart.png";
import tigers from "../assets/vector/tigers.png";
import pixelmatch from "../assets/vector/pixelmatch.png";
import streams from "../assets/vector/streams.png";

import globedemo from "../assets/d3passion/globedemo.gif";
import stripes from "../assets/d3passion/stripes.gif";
import plastic from "../assets/d3passion/plastic-fullh.png";
import cube from "../assets/d3passion/cubedemo2.gif";
import p2 from "../assets/d3passion/p2demo.gif";

import Gallery from "react-photo-gallery";
import aprysedemo from "../assets/aprysedemo.gif";

export const CloudContent = () => {
  const lambdaCode = `import boto3

dynamodb = boto3.resource('dynamodb')
table = dynamodb.Table('cloudresume')

def handler(event, context):
    response = table.get_item(Key={
        'id':'1'
    })
    views = response['Item']['views']
    views += 1
    response = table.put_item(Item={
            'id':'1',
            'views': views
    })

    return views`;
  return (
    <div>
      <h4>About The Challenge</h4>
      <p>
        The Cloud Resume Challenge is a hands-on project designed to develop
        practical cloud skills. Steps involve automated testing, building CI/CD
        pipelines, database integration, DNS configuration, templating
        infrastructure as code, and more.
      </p>
      <p>
        Read about the challenge{" "}
        <a href="https://cloudresumechallenge.dev/docs/the-challenge/aws/">
          here
        </a>
        .
      </p>
      <br />
      <h5>Completed Steps</h5>
      <input
        type="checkbox"
        id="aws-certification"
        name="aws-certification"
        value="AWS Certification"
        checked
      />
      <label for="aws-certification">AWS Certification CCP + CSAA</label>
      <br />
      <input
        type="checkbox"
        id="static-website"
        name="static-website"
        value="Static Website"
        checked
      />
      <label for="static-website">Static Website</label>
      <br />
      <input
        type="checkbox"
        id="https-cloudfront"
        name="https-cloudfront"
        value="HTTPS with Cloudfront"
        checked
      />
      <label for="https-cloudfront">HTTPS with Cloudfront</label>
      <br />
      <input
        type="checkbox"
        id="dns-route53"
        name="dns-route53"
        value="DNS with Route53"
        checked
      />
      <label for="dns-route53">DNS with Route53</label>
      <br />
      <input
        type="checkbox"
        id="lambda-dynamodb"
        name="lambda-dynamodb"
        value="Lambda and DynamoDB"
        checked
      />
      <label for="lambda-dynamodb">Lambda and DynamoDB for Visit Counter</label>
      <br />
      <input
        type="checkbox"
        id="source-control"
        name="source-control"
        value="Source Control"
        checked
      />
      <label for="source-control">Source Control</label>
      <br />
      <input
        type="checkbox"
        id="cicd-frontend"
        name="cicd-frontend"
        value="CI/CD Frontend"
        checked
      />
      <label for="cicd-frontend">CI/CD Frontend</label>
      <br />
      <input
        type="checkbox"
        id="cicd-backend"
        name="cicd-backend"
        value="CI/CD Backend"
        checked
      />
      <label for="cicd-backend">CI/CD Backend</label>
      <br />
      <input
        type="checkbox"
        id="iac"
        name="iac"
        value="Infrastructure as Code"
        checked
      />
      <label for="iac">Infrastructure as Code</label> <br />
      <input type="checkbox" id="tests" name="tests" value="Tests" checked />
      <label for="tests">Tests</label>
      <br />
      <br />
      <h4>Challenge Process</h4>
      <h5>Step 0 - Certification</h5>
      <p>
        I actually got certified as an AWS Cloud Practitioner and Solutions
        Architect Assosicate before I had heard about this challenge so I was
        able to effectively skip this step. I was certified on May 2022 and
        January 2024 respectively, which can be verified{" "}
        <a href="https://www.credly.com/earner/earned/badge/0bb8639e-fa4c-4cc3-a843-eec3cbb9a748">
          here
        </a>
        .
      </p>
      <br />
      <h5>Step 1 - Frontend</h5>
      <p>
        Every year I rebuild my portfolio website. For 2024, I had already
        redesigned my website with React and this was the app I used as my
        frontend for this challenge.
      </p>
      <br />
      <h5>Step 2 - Deploy Website to AWS S3 Bucket</h5>
      <p>
        Since my app was built with React, I generated static files by running
        npm run build. The build folder was uploaded to the S3 bucket named
        www.amyjo.cloud. Static website hosting was enabled and index.html was
        set as the index document. S3 bucket permissions and resource policy
        were also set to allow public access.
      </p>
      <br />
      <h5>Step 3 - Website Domain Setup</h5>
      <p>
        I bought the custom domain name amyjo.cloud through Godaddy. In
        Godaddy's DNS configuration settings, I updated the CNAME records with
        the S3 bucket name.
      </p>
      <br />
      <h5>Step 4 - Cloudfront CDN Distribution</h5>
      <p>
        I created a Cloudfront content delivery network to speed up the
        distribution of my website. The origin domain was set to the S3 bucket,
        the domain name to www.amyjo.cloud, and subdomain name to amyjo.cloud.
      </p>
      <br />
      <h5>Step 5 - Custom Certificate Request</h5>
      <p>
        To enable HTTPS, I requested a certificate in AWS ACM for the domain
        names www.amyjo.cloud and amyjo.cloud. In Godaddy, I then updated the
        CNAME records with the certificate's CNAME values.
      </p>
      <br />
      <h5>Step 6 - Route53 DNS</h5>
      <p>
        I transferred my domain to AWS's domain name service (DNS) Route53. I
        first created a public hosted zone within Route53 with the domain name
        www.amyjo.cloud then changed nameservers at Godaddy to use Route53's
        nameservers.
      </p>
      <br />
      <h5>Step 7 - Visit Counting with DyanmoDB</h5>
      <p>I provisioned a DynamoDB table with the attribute key and views.</p>
      <br />
      <h5>Step 8 - Visit Counting with AWS Lambda</h5>
      <p>
        I created an AWS Lambda function with the AWS SDK for Python, Boto3. The
        function updates the view attribute in the dynamodb table and returns
        the number of views. Function URL was enabled so that we could request
        the URL directly instead of provisioning an API Gateway. This Lambda
        function was also allowed to assume an IAM role that had permissions to
        access DynamoDB.
      </p>
      <br />
      <CopyBlock
        language="python"
        text={lambdaCode}
        showLineNumbers={true}
        wrapLines={true}
        codeBlock
      />
      <br />
      <h5>Step 9 - Update Website with Visit Counter</h5>
      <p>
        I updated the website to fetch the Lambda function URL and display the
        number of views.
      </p>
      <br />
      <h5>Step 10 - Infrastructure as Code</h5>
      <p>
        I automated infrastructure with Terraform. I migrated the resources to
        Terraform by configurating the files, terraform importing the resources,
        and using terraform plan to carefully observe changes. terraform init
        initializes Terraform configuration files terraform apply deploys the
        specified resources terraform destroy destroys all resources specified
        in Terraform's configuration files
      </p>
      <br />
      <h5>Step 11 - Frontend CI/CD Workflow</h5>
      <p>
        I setup a CI/CD workflow with Github Actions so upon every push on Git,
        the code will build and sync to the S3 bucket.
      </p>
      <br />
      <h5>Step 12 - Backend CI/CD Workflow</h5>
      <p>
        I setup a CI/CD workflow with Github Actions so upon every approved
        merge request, Terraform will update remote resources.
      </p>
      <br />
      <h5>Step 13 - Automated Testing</h5>
      <p>
        I setup automated testing with Jest and Supertest to ensure the Visit
        Count Lambda function was running correctly. The Backend CI/CD was then
        updated to ensure Lambda still functions with the new Terraform plan.
      </p>{" "}
      <br />
      <h4>Challenges</h4>
      <p>
        Due to how Cloudfront and DNS routing works, sometimes it took up to 24
        hours for any changes in code or domain name to appear. This is still
        the case for any code pushes in the frontend app, making it difficult to
        validate changes in production. To handle this, I setup live Vercel
        deployments to ensure the expected changes were there.
      </p>
      <br />
      <p>
        I also set my region as ca-central-1 (West Canada) for my Terraform
        provider as this was the closest location to me. This was problematic as
        a certificate from the AWS Certificate Manager could only be requested
        from us-east-1 in order to use it with Cloudfront. Setting up multiple
        providers in a convenient way in Terraform took some time but I
        eventually succeeded by differentiating the default and aliased
        providers.
      </p>
      <br />
      <p>
        In addition, I didn't want any downtime during the transition of my
        manually provisioned AWS resources to the ones provisioned by Terraform.
        I did more research and discovered I could import manually created
        resources, which I did so. After importing all my resources, I used the
        "terraform plan" command to ensure there were minimal changes and that
        no resources were being destroyed.
      </p>
      <br />
      <h4>Costs</h4>
      <p>
        Hosting my portfolio on AWS was very affordable. I ran a monthly cost of
        $0.81 the first month for using their services with most of the cost
        coming from Route53's hosted zone. Beyond that, I paid around $4/yr for
        a custom domain name from Godaddy.
      </p>
    </div>
  );
};

export const D3Content = () => {
  const photos = [
    {
      src: globedemo,
      width: 2,
      height: 1,
    },
    {
      src: stripes,
      width: 4,
      height: 3,
    },
    {
      src: plastic,
      width: 2,
      height: 3,
    },
    {
      src: p2,
      width: 3,
      height: 1,
    },
    {
      src: cube,
      width: 3,
      height: 1,
    },
  ];

  return (
    <>
      <p>A demo of various passion projects made with D3.</p>
      <p>
        They visualize annual surface temperature changes, plastic pollution
        generation, prevalence of preschool night blindness, political leader
        demographics, and gender related data events.
      </p>
      <br />
      <Gallery photos={photos} />;
    </>
  );
};

export const ApryseContent = () => {
  const resdemo = `import React from "react";

const ResponsiveComponent = () => {
  return (
    <div className="p-4">
      <h1 className="text-xl md:text-3xl lg:text-5xl font-bold text-gray-800">
        Responsive Design
      </h1>
      <p className="mt-4 text-sm md:text-base lg:text-lg">
        This text size adjusts based on the screen size.
      </p>
      <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <div className="p-4 bg-blue-200">Item 1</div>
        <div className="p-4 bg-blue-300">Item 2</div>
        <div className="p-4 bg-blue-400">Item 3</div>
        <div className="p-4 bg-blue-500">Item 4</div>
      </div>
    </div>
  );
};

export default ResponsiveComponent;
`;

  const adaptdemo = `import React from "react";

const AdaptiveComponent = () => {
  return (
    <div className="p-4">
      <h1 className="font-bold text-gray-800">
        <span className="block text-xl sm:hidden">Adaptive Design (Mobile)</span>
        <span className="hidden sm:block md:hidden text-3xl">Adaptive Design (Tablet)</span>
        <span className="hidden md:block lg:hidden text-4xl">Adaptive Design (Small Desktop)</span>
        <span className="hidden lg:block text-5xl">Adaptive Design (Large Desktop)</span>
      </h1>
      <div className="mt-4">
        <p className="sm:hidden">This is designed for mobile screens.</p>
        <p className="hidden sm:block md:hidden">This is designed for tablets.</p>
        <p className="hidden md:block lg:hidden">This is designed for small desktops.</p>
        <p className="hidden lg:block">This is designed for large desktops.</p>
      </div>
    </div>
  );
};

export default AdaptiveComponent;
`;
  return (
    <>
      {" "}
      <h4>Overview</h4>
      <p>
        During my internship at Apryse, I had the opportunity to rotate through
        various teams, which allowed me to contribute to a wide range of
        projects. I successfully built custom tooling, researched and developed
        open-source libraries, and designed WebViewer APIs. My final task was
        particularly impactful: helping to redesign the company’s website.
      </p>
      <br />
      <p>
        From September 2022 to December 2022, I was part of a dedicated team of
        four developers tasked with overhauling the website during PDFTron's
        rebranding. Our goal was to launch the new site by the new year in time
        for unveiling the rebranding event to the public. Beyond just visual
        changes, we focused on creating a more flexible architecture that would
        allow non-technical staff to update the website independently, which was
        a significant change from how developers maintained all the websites
        before.
      </p>
      <br />
      <p>
        To facilitate this transition, we implemented Sanity CMS, ensuring that
        the content team could easily manage the site’s updates moving forward.
        We also utilized technologies like TailwindCSS, TypeScript, and Next.js
        to build a modern, efficient, and scalable site.
      </p>
      <br />
      <p>
        One of my responsibilities involved developing reusable and responsive
        components. This deepened my understanding of responsive design,
        particularly in distinguishing it from adaptive design. Initially, I
        thought responsive design was merely about ensuring that an application
        renders well across all screen sizes. However, I learned about the
        differences and advantages between responsive and adaptive design.{" "}
      </p>{" "}
      <br />
      <p>
        You can recognize an adaptive website by observing the layout changes
        abruptly when accessing the site from different devices or screen sizes,
        as it’s loading predefined layouts. In contrast, the layout adapts more
        fluidly in responsive design. For example, observe the following
        Tailwind examples.
      </p>
      <br />
      <CopyBlock
        language="jsx"
        text={resdemo}
        showLineNumbers={true}
        wrapLines={true}
        codeBlock
      />
      <br />
      <br />
      <CopyBlock
        language="jsx"
        text={adaptdemo}
        showLineNumbers={true}
        wrapLines={true}
        codeBlock
      />
      <br />
      <h4>Responsive Design vs Adaptive Design</h4>
      <p>
        Responsive design offers a consistent user experience across all
        platforms and works well on new devices, even those with non-standard
        screen dimensions. However, it provides less control over how the site
        renders on each device, which can hinder visual hierarchy. Additionally,
        it requires more design expertise and is not always optimized for
        performance, as dynamic content can take longer to load.
      </p>
      <br />
      <p>
        On the other hand, adaptive design creates a tailored experience for
        each platform and is highly performant since the design is optimized for
        the target device. The downside is that it requires increased
        development effort to create different layouts for each device, leading
        to limited layout flexibility and higher maintenance costs.
        Additionally, adaptive design can negatively impact SEO.
      </p>
      <br />
      <h5>Apryse Landing Page Demo</h5>
      <img src={aprysedemo} alt="demo" />
    </>
  );
};

export const LonelinessContent = () => {
  return (
    <>
      <h4>Overview</h4>
      <p>
        The feeling of loneliness can sometimes feel inescapable. In many, it
        can become chronic and even lead to depression. In others, it can spur a
        need to take action and go out more. Why do we feel lonely? And what
        factors contribute to it? What can we discover about loneliness and its
        patterns?
      </p>
      <br />
      <p>
        These are the questions that led us to explore the topic of loneliness
        for our project. For our project, we wanted to focus on the everyday
        impacts and factors that contribute to loneliness.
      </p>
      <br />
      <p>
        We wanted to take a data-driven story approach to talk about the issue
        of loneliness and its factors and see correlations and interesting
        patterns for ourselves and others alike.
      </p>
      <br />
      <p>We plan on visualizing 3 key questions around loneliness:</p>
      <br />
      <p>Why is loneliness relevant today?</p>
      <br />
      <p>What factors contribute to loneliness?</p>
      <br />
      <p>Are there people like me that also feel lonely?</p>
      <br />
      <p>
        The target audience here would be people who feel lonely in their
        everyday lives and would like to know more about loneliness and what
        they can do to cope with it.
      </p>
      <br />
      <h4>Data and Data Pre-Processing</h4>
      <br />
      <p>Dataset 1: StatsCan</p>
      <br />
      <p>Dataset 2: COVIDiSTRESS</p>
      <br />
      <p>
        For dataset 2, we used Jupyter Notebook and Panda to conduct
        preprocessing.
      </p>
      <br />
      <p>
        We first removed any columns/attributes we weren’t using then removed
        any items (survey responses) with null values, which reduced the
        available data points from 139k to 79k. We then verified all columns had
        non-null values and that the values were what we expected. Except for
        the trust column, all of our attribute types were categorical so there
        was no need to handle outliers. For the trust column, we verified that
        its range was restricted from 0 to 10. The Python script is attached in
        the project repo under preprocessing.
      </p>
      <br />
      <p>
        For dataset 1, it wasn’t possible to download the dataset, but since
        there weren’t too many rows we just manually copied them to Excel and
        executed preprocessing in JavaScript on the fly.
      </p>
      <br />

      <h4>Scrollytelling</h4>
      <p>
        We chose scrollytelling because we wanted to present the information in
        a modular way to avoid overloading the viewer. Scrollytelling also
        allows us to present our topic with textual context and employ a
        narrative-driven approach that is more engaging and immersive.
      </p>
      <br />
      <img src={longDemo} alt="demo" />

      <h4>Usage Scenario</h4>
      <p>
        Ever since a grueling period of self-isolation and online classes during
        the COVID-19 pandemic, Alice finds herself feeling lonely these days,
        and searches up facts and resources on loneliness. She happens to
        stumble upon this data visualization on loneliness and is very curious
        and thinks she might feel a bit better too if she knew more about
        loneliness.
      </p>
      <br />
      <h4>Loneliness Distress Dot Chart</h4>
      <img src={dot} alt="dot" />
      <p>
        When she enters the site, Alice scrolls down straight away and the text
        changes to “How is loneliness affecting us now?” She reads underneath
        the title: “A look into the COVID-19 pandemic and how it has affected
        people from across the world.” Hmm… Alice is more curious now. She wants
        to find out what loneliness looked like for others during the pandemic.
        She clicks the next dot indicator on the right (below the current
        highlighted one) and she is presented with a dot chart.
      </p>
      <br />
      <p>
        From the dot chart, she is able to see that each dot represents 240
        people and how many dots fall into each loneliness category. To her
        surprise, she finds out that the majority of people disagreed that they
        felt distressed over loneliness during the COVID pandemic. This has
        piqued her interest.
      </p>
      <br />
      <h4>Sankey Diagram</h4>

      <img src={sankey} alt="sankey" />
      <p>
        She uses the down arrow key to navigate to the next page and discovers a
        Sankey diagram that shows levels of isolation compared to loneliness
        levels. At this point, she wants to know more about if people like her
        who were isolated also felt very lonely (1), and how many? She sees the
        links that map to the “Isolated” level and is curious about it.
      </p>
      <br />
      <p>
        She moves her cursor to the Isolated endpoint and through the tooltip,
        finds that 14.9% of people were Isolated and “Strongly Agreed” that they
        felt lonely.
      </p>
      <br />
      <p>
        She then moves her cursor to one Isolation category above — “Life
        carries on with minor changes” and realizes that only 7.2% of people in
        this category strongly agreed that they were lonely. “Interesting”, she
        thinks, realizing that isolation does affect feelings of loneliness.
      </p>
      <br />
      <p>
        She continues to the next page because, although she is not feeling the
        best, she is still a curious soul.
      </p>
      <br />
      <h4>Personality Scatterplot (Side-by-Side Scatterplot)</h4>

      <p>
        She hits the down key again and sees the text “What behavioral factors
        correlate to loneliness?” and sees the tag “Introversion vs.
        Extroversion” below it.
      </p>
      <br />
      <p>
        She excitedly hits the down key again and sees 2 scatterplots side by
        side with emojis as points. “WAIT IS THIS A CHART ON INTROVERSION VS.
        EXTRAVERSION ??” she thinks very loudly as she reads the title. She is
        excited, and we, the creators, are smiling as well (note: we are
        imagining this entire scenario because we are hoping people like our
        cool graphs).
      </p>
      <br />
      <p>
        She wants to know if sociability affects feelings of isolation (3). She
        sees that there is an average isolation percentage on the scatterplots
        and is able to tell how far the points deviate from the average
        isolation. There are emojis with facial expressions to show varying
        degrees of their feelings of isolation with the happy orange face being
        the least isolated and sad blue face being the most isolated. Looking at
        the emojis and comparing their positions, Alice can see how introversion
        and extraversion affect one’s feelings of isolation. She notes that it
        is interesting how there is not a big difference in how introversion and
        extraversion changes with feelings of isolation.
      </p>
      <br />
      <h4>Social Traits Scatterplot</h4>

      <img src={scatter} alt="scatter" />
      <p>
        She continues onto the next page and is greeted with another
        scatterplot. This time, she notices ta drop-down on the “Time Spent
        Indoors” and clicks on it, discovering she can also select other social
        traits. Once again, by looking at the points and comparing their
        positions, Alice can observe if there are any patterns in feelings of
        isolation, such as whether staying indoors consistently relates to
        feeling more or less isolated.
      </p>
      <br />
      <h4>Bi-directional Graph</h4>

      <img src={bar} alt="bar" />
      <p>
        She moves to the next page and reads “Are there people like me who feel
        lonely?”. There is a bar chart above several stacked horizontal bar
        charts. “Hmmm…”, she takes a scan because this one seems more
        complicated and sees many different factors. She wants to know about
        gender (4), so she hovers on each female and male bar, and finds out the
        total number of females vs. males.
      </p>
      <br />
      <p>
        From here, she realizes clicking on a bottom bar shows the total number
        of people in each loneliness category. She’s curious so she wants to
        know if she can filter by loneliness too (4). When she clicks on the
        bars above, the percentage changes for the bars below and shows the
        selection she inputted. For example, if she clicks on “Sometimes feels
        lonely” on the top bar chart, it deselects all data that corresponds to
        “Sometimes feels lonely” and re-calculates the percentages for the
        stacked bars below.
      </p>
      <br />
      <p>
        She also hovers around and sees she can click on the stacked bars to
        update the top chart to match/show the category’s distribution. She
        clicks on the “Female” category because she is curious about the
        distribution for females. When she clicks, she is able to see the number
        of responses per category by looking at the bar chart itself (as well as
        hovering above each bar).
      </p>
      <br />
      <p>
        She goes to the next page and realizes the visualization has ended. She
        leaves feeling a little cooler, knowing that she knows more about
        loneliness. Although it doesn’t make her necessarily feel any less
        lonely, she feels inspired to send this to friends that she hasn’t
        talked to in a while.
      </p>
      <br />
      <h4>Tasks that we touched on:</h4>
      <br />
      <p>
        (1) whether COVID isolation has a correlation to how others felt
        distressed over loneliness
      </p>
      <br />
      <p>
        (2) how did the COVID-19 pandemic influence the experience of distress
        associated with loneliness?
      </p>
      <br />
      <p>
        (3) whether aspects of a person’s personality such as sociability and
        trust affect their feelings of isolation
      </p>
      <br />
      <p>
        (4) whether/how a person’s socio-demographic characteristics affect
        their loneliness
      </p>
    </>
  );
};

export const SignatureContent = () => {
  const demoCode = `const scaleFontSize = (text, fontFamily) => {
 let minFontSize = 0;
 let maxFontSize = TYPED_SIGNATURE_FONT_SIZE;
 let currentFontSize;


 while (minFontSize <= maxFontSize) {
   currentFontSize = Math.floor((minFontSize + maxFontSize) / 2);


   const signatureWidth = getSignatureLength(text, currentFontSize, fontFamily);
   if (signatureWidth > MAX_SIGNATURE_LENGTH) {
     maxFontSize = currentFontSize - 1;
   } else {
     minFontSize = currentFontSize + 1;
   }
 }
`;
  return (
    <>
      <div class="container">
        <p>
          During my internship at Apryse, I focused on enhancing WebViewer, the
          company's flagship document SDK. One key feature I developed was the
          signature styles functionality. Prior to this, users were limited to a
          single default font for typed signatures. My enhancement enabled users
          to select from a variety of font styles through both the user
          interface and the API.
        </p>
        <br />
        <p>
          Integrating multiple font families seemed straightforward
          initially—simply add a filter to select different fonts. However, this
          led to a new challenge: the text signatures did not always fit within
          their containers, causing the modal to overflow.
        </p>
        <br />
        <p>
          To address this, I first researched how other industry applications
          handled signature modals. For instance, SmallPDF dynamically adjusted
          font sizes based on the selected font family rather than the length of
          the signature. This approach inspired my implementation strategy.
        </p>
        <br />
        <img src={smallpdf} alt="smallpdf" />
        <br />
        <p>
          I encapsulated the signatures within an invisible span that matched
          the container's dimensions. The key difference was that the span had a
          fixed size, while the container would become scrollable if the text
          exceeded its bounds. This setup was crucial because any text extending
          beyond the container would be reflected in an invisible canvas used to
          export the signature as an image, which the users would not expect.
        </p>
        <br />
        <p>
          This approach solved the overflow issue but introduced another
          problem: adjusting the font size efficiently. A naive implementation
          would start with the initial text size and iteratively decrease it
          until the text fits within the invisible span. But this would result
          in excessive re-renders and take too much time.
        </p>
        <br />
        <p>
          To optimize this, I implemented a binary search algorithm to find the
          appropriate font size more efficiently. This method suitably reduced
          the number of re-renders and improved performance.
        </p>
        <br />
        <CopyBlock
          language="jsx"
          text={demoCode}
          showLineNumbers={true}
          wrapLines={true}
          codeBlock
        />
        <br />
        <p>
          The final implementation balanced usability with performance, ensuring
          a seamless user experience.
        </p>
        <br />

        <img src={signatureDemo} alt="demo" />
        <p>
          The final code and UI are publicly available on GitHub under
          webviewer-ui as well as Apryse’s showcase.
        </p>
        <br />
      </div>
    </>
  );
};

export const VectorContent = () => {
  const demo1 = `WebViewer({
  // options
}, document.getElementById('viewer'))
  .then((instance) => {
    const { Annotations, documentViewer } = instance.Core;

    documentViewer.addEventListener('documentLoaded', async () => {
      const rectangle = new Annotations.RectangleAnnotation();
      rectangle.PageNumber = 1;
      rectangle.X = 10;
      rectangle.Y = 150;
      rectangle.Width = 235;
      rectangle.Height = 200;
      rectangle.FillColor = new Annotations.Color(0, 0, 0);

      // note that if you are adding multiple appearances you should make sure they have unique file names
      const doc = await instance.Core.createDocument('https://pdftron.s3.amazonaws.com/downloads/pl/tiger.pdf', {
        useDownloader: false,
        filename: 'tiger.pdf'
      });
      rectangle.addCustomAppearance(doc, { pageNumber: 1 });

      documentViewer.getAnnotationManager().addAnnotation(rectangle);
      documentViewer.getAnnotationManager().redrawAnnotation(rectangle);
    });
  });`;
  const demo2 = ` const blob = await createAppearance(canvasFn);
const doc = await Core.createDocument(blob, { extension: "pdf" });
annotation.addCustomAppearance(doc, { pageNumber: 1 });`;

  const demo3 = `canvas2pdf.PdfContext.prototype.moveTo = function (x, y) {
   this._addToPath("moveTo", arguments); // this line was added
   this.doc.moveTo(x, y);
};`;

  const demo4 = `canvas2pdf.PdfContext.prototype._addToPath = function (command, params) {
   this.stack.push({ command, params });
};
canvas2pdf.PdfContext.prototype._restorePath = function () {
   this.stack.forEach((key) => {
     if (this.stack && key && this.doc[key.command]) {
       this.doc[key.command].apply(this.doc, key.params);
} });
};`;

  const demo5 = `canvas2pdf.PdfContext.prototype.stroke = function () {
  this.doc.stroke();
  this._restorePath();
};
canvas2pdf.PdfContext.prototype.fill = function () {
  this.doc.fill();
  this._restorePath();
};`;
  const demo6 = `const createAppearance = (fn) => {
  var stream = blobStream();
  var ctx = new canvas2pdf.PdfContext(stream);
  fn(ctx);
  ctx.end();
   return new Promise((resolve, reject) => {
    ctx.stream.on("finish", async function () {
      const blob = ctx.stream.toBlob("application/pdf");
      resolve(blob);
    });
}); };`;
  const demo7 = `const draw = (ctx) => {
    ctx.fillStyle = "red";
    ctx.lineWidth = "20";
    ctx.strokeStyle = "black";
    ctx.rect(100, 100, 200, 200);
    ctx.fill();
    ctx.stroke();
  };
createAppearance(draw).then((res) => {
    saveAs(res, "appearance.pdf", true);
});`;
  const demo8 = `
  // buffer from api  
  var apiBuffer = blob.arraybuffer()
  
  // buffer from canvas
  var imageData = context.getImageData(x, y, w, h);
  var canvasBuffer = imageData.data.buffer;`;
  const demo9 = `
  let blob = await createAppearance((ctx) => {
  drawCase(caseNumber, ctx);
});
 let url = URL.createObjectURL(blob);
await convertPdfToCanvasImage(url, imagedata1);
const convertPdfToCanvasImage = async (url, imagedata1) => {
   let doc = await pdfjsLib.getDocument(url).promise;
   let page = await doc.getPage(1);
   var scale = 1;
   var viewport = page.getViewport({ scale });
   let Contex = initCanvas();
   var renderContext = {
     canvasContext: Contex,
     viewport: viewport,
   };
   await page.render(renderContext).promise;
   let imagedata2 = Contex.getImageData(0, 0, width, height).data;
   let numDiffPixels = pixelmatch(imagedata1, imagedata2, null, width, height, {
     threshold: 0.1,
}); };`;
  const demo10 = ` const uri1 =
   // hardcoded data
 let blob = await createAppearance((ctx) => {
   // canvas methods
 });
 const doc = await window.Core.createDocument(blob, { extension: "pdf" });
return new Promise((resolve, reject) => {
   doc.loadCanvas({
     pageNumber,
     zoom: 1,
     multiplier: 1,
     drawComplete: async (thumbnail) => {
       const uri2 = thumbnail.toDataURL();
       return uri1 === uri2;
}, });
});`;
  return (
    <>
      <h4>Background information</h4>
      <p>
        Annotation appearances are a special way of rendering an annotation
        inside a PDF and can be useful in scenarios where users require custom
        annotations. Usually, appearances involve rasterization, the process of
        converting vector graphics into a pixelated image, which leads to
        blurriness of the annotation when you zoom in. Vector graphics create
        images from mathematical paths and thus maintain their image quality
        even if they are infinitely zoomed on.
      </p>
      <br />
      <h5>Appearances in PDFTron</h5>
      <p>
        WebViewer supports adding a PDF object or page as an appearance to any
        annotation type. This allows an annotation to be displayed in a custom
        way which overrides the default rendering based on the annotation
        properties. The current implementation uses the toDataURL method to
        rasterize the canvas and merge the resulting image with the original
        PDF. While this allows for appearances in PDF files, because the canvas
        is rasterized, there is a noticeable loss in image quality.
      </p>
      <br />
      <p>
        The current implementation for appearances has the WebViewer core
        WebAssembly expecting a PDF Document object containing the annotations
        to be passed in order for it to process and merge into the PDF without
        annotations.
      </p>
      <br />
      <p>
        The goal of this project is to be able to call on a newly created API
        that can create vector appearances with canvas methods to minimize the
        difference between how regular appearances and vector appearances are
        drawn. The vector appearances should also be able to be merged with an
        original PDF just as regular annotations are being merged.
      </p>
      <br />
      <p>
        Code-wise, regular annotations are merged with an original document
        using the addCustomAppearance method. Below is an example of how it can
        be used right now.
      </p>
      <br />
      <CopyBlock
        language="jsx"
        text={demo1}
        showLineNumbers={true}
        wrapLines={true}
        codeBlock
      />
      <br />
      <p>
        addCustomAppearance accepts a PDF document object containing custom
        annotations as a parameter. Thus the new API should be able to return a
        PDF document object as well.
      </p>
      <br />
      <p>
        Consequently, the goal is to use the new API in a similar manner to
        below:
      </p>
      <br />
      <CopyBlock
        language="jsx"
        text={demo2}
        showLineNumbers={true}
        wrapLines={true}
        codeBlock
      />
      <br />
      <h5>Requirements</h5>
      <p>
        To summarize the above, we have the following requirements. The new API
        should:
      </p>
      <br />
      <ol type="1">
        <li>Create vector appearances</li>
        <li>Support Canvas methods</li>
        <li>Be able to return a PDF document object</li>
        <li>And work client-side</li>
      </ol>
      <br />
      <h4>Investigation</h4>
      <p>
        Several third-party libraries were investigated to see if they could
        meet our needs in creating vector appearances with canvas methods. These
        libraries include canvas2pdf, jsPDF, and dom-to-image.
      </p>
      <br />
      <br />
      <p>Canvas to PDF libraries</p>
      <img src={compare} alt="compare" />
      <p>
        Note that although dom-to-image can store vector graphics, they can only
        convert canvas to SVG or canvas to images, which is not ideal for our
        purposes, and while jsPDF can convert canvas to pdf, they also rasterize
        the images and are thus not appropriate for our use case. For this
        reason, canvas2pdf is the best option of the three as it can store
        vector graphics and does not have the problems the other libraries do.
        The ease of use was checked by creating simple demos with each of these
        3 libraries and it was noted using canvas2pdf was straightforward and
        easy. However, do note that none of these libraries provide a pdf object
        return which is a required input for WebAssembly in order for
        appearances to work. We will have to implement this ourselves.
      </p>
      <br />
      <br />
      <p>
        In case direct conversion from canvas to pdf was not possible,
        converting a canvas to SVG and converting the SVG to pdf was considered
        as well. Upon exploring this avenue, it was concluded that while this
        was likely possible, it would not be the most suitable solution when a
        direct conversion was feasible. More information about this is available
        in the WebViewer Vector Appearances API Investigation doc.
      </p>
      <br />
      <h4>About Canvas2PDF</h4>
      <p>
        Canvas2PDF exports your HTML canvas as PDF using JavaScript. Note that
        this library generates actual PDF drawing calls to create a PDF with
        vector graphics, unlike some alternate libraries which rasterize your
        canvas and place it as an image in your PDF. It uses PDFKit as a
        dependency to call on PDF drawing methods.
      </p>
      <br />
      <br />
      <h5>Problems</h5>
      <br />
      <p>
        Although Canvas2PDF renders most canvas methods correctly on a PDF, it
        has a well-known issue where if calling fill or stroke consecutively
        only executes the first method. This problem is illustrated below where
        the tiger image on the right is lacking borders.
      </p>
      <br />
      <img src={tigers} />
      <h6>
        Left is the expected result drawn in an HTML canvas; right is canvas2pdf
        result
      </h6>
      <br />
      <br />
      <p>
        It should be noted that although Canvas2PDF supports the majority of
        canvas methods, methods such as setTransform, createPattern,
        setLineDash, etc are not.
      </p>
      <br />
      <h4>Approaches to Fixing Canvas2PDF</h4>
      <p>
        Fixing Canvas2PDF’s fill and stroke issues was a must before designing
        the API.
      </p>
      <br />
      <p>
        First, several test cases were set up. Regular canvas methods were
        called to generate rasterized annotations as a baseline.
      </p>
      <br />
      <p>Initial baseline test cases:</p>
      <p>1. Rectangle</p>
      <p>2. Ellipse</p>
      <p>3. Line</p>
      <p>4. Hatch</p>
      <p>5. Note Annotation</p>
      <p>6. Tiger </p> <br />
      <h5>1. Modify PDFKit Fill and Stroke</h5>
      <br />
      <p>
        PDftron’s low-level PDF editor Cosedit was used to examine the PDF
        operators of Canvas2PDF’s generated images that had fill and stroke
        issues. By inspecting the vector streams of the generated images and
        manually modifying the PDF path painting operators, a pattern for fixing
        the streams was observed.
      </p>
      <br />
      <p>Vector Stream of Simple Ellipse Annotation in Cosedit</p>
      <br />
      <img src={streams} />
      <p>
        Left is the vector stream from canvas2pdf without any modification;
        Right has the modified vector stream
      </p>
      <br />
      <br />
      <p>
        Left is the canvas2pdf generated image without any modification; Right
        is the image with the modified vector stream
      </p>
      <br />
      <p>
        As seen above, modifying W n f S -> W B solved the problem. Similar
        patterns where modifying f S -> B in other annotations where the W
        operator was absent also had the same effect.
      </p>
      <br />
      <img src={adobespec} />
      <p>
        This is because path-painting operators (such as S, f, or b,), end the
        path object and leave no path for a subsequent fill or stroke to paint
        on. Using the B operator ensures fill and stroke is executed at the same
        time, thus eliminating the problem of no paths to fill or stroke on.
      </p>
      <br />
      <p>
        Since PDFKit was the library that handled PDF operators, the fill and
        stroke methods in the PDFKit file were modified. Instead of inserting a
        f for fill and S for stroke into the vector stream, the methods were
        modified to add a B operator in both the fill and stroke methods
        instead. Doing this ensured that all initial baseline test cases passed.
        However, stress testing the code with high linewidth values showed that
        PDFKit will paint the linewidth twice, leading to annotations with
        borders much thicker than expected. For this reason, this solution was
        not pursued further.
      </p>
      <br />
      <h5>2. Implement Custom Save and Restore</h5>
      <br />
      <p>
        Another possible solution to the fill and stroke problem was by using
        save and restore to keep track of the current path. Since the obstacle
        arises from the fact that fill and stroke close the path, the idea is
        that we would restore the path before either of them is called. How we
        can implement it is to have a stack variable that would keep track of
        our current path. Every time a path modifying canvas method is called,
        we would add the method to the stack (fill or stroke will be added to
        the stack since they would close it). When either fill or stroke is
        called, we would call all methods inside the stack to restore the path.
      </p>
      <br />
      <p>
        The canvas methods in Canvas2PDF do not directly translate to the HTML
        canvas methods and thus may behave differently than expected. For this
        reason, custom save and restore methods were implemented.
      </p>
      <br /> <br />
      <CopyBlock
        language="jsx"
        text={demo3}
        showLineNumbers={true}
        wrapLines={true}
        codeBlock
      />
      <br />{" "}
      <CopyBlock
        language="jsx"
        text={demo4}
        showLineNumbers={true}
        wrapLines={true}
        codeBlock
      />{" "}
      <CopyBlock
        language="jsx"
        text={demo5}
        showLineNumbers={true}
        wrapLines={true}
        codeBlock
      />
      <p>Path Modifying Canvas Methods are Added to the Stack</p>
      <br />
      <p>
        Do note that not all methods in canvas2pdf are being added to the stack.
        It might be expected that methods such as bezierCurveTo, rect, and
        moveTo call on addToPath, but others such as clip or translate do not.
        Take care that when using addToPath to a method, it is actually
        modifying the path.
      </p>
      <br />
      <p>
        Other modifications included manually closing the path by inserting the
        n operator in the vector stream before calling the following:
      </p>
      <p>1. Rect</p>
      <p>2. Arc</p>
      <p>3. Beginpath 4. Fillrect</p>
      <p>5. Strokerect</p>
      <br />
      <h4>API Design</h4>
      <br />
      <p>
        The createAppearance api allows a user to call canvas methods on a pdf
        to create appearances. It takes in a function containing canvas methods
        as a parameter and outputs a blob. A user can then optionally use the
        FileSaver library to convert the blob to a pdf and download it to view
        the pdf. Or a user can use the blob with other apis such as Pdftron's
        webviewer to make further modifications.
      </p>
      <br />{" "}
      <CopyBlock
        language="jsx"
        text={demo6}
        showLineNumbers={true}
        wrapLines={true}
        codeBlock
      />
      <br />
      <br />
      <CopyBlock
        language="jsx"
        text={demo7}
        showLineNumbers={true}
        wrapLines={true}
        codeBlock
      />
      <br />
      <p>
        The initial API and its dependencies were bundled using Webpack as a
        bundle.js file to make loading it as a dependency simpler. In the
        future, a canvasToPDF npm module will be released for wider
        distribution.
      </p>
      <br />
      <h4>Automated Tests</h4>
      <br />
      <p>
        To verify that the appearance API was outputting the same image as an
        HTML canvas given the same canvas methods, a few approaches were
        considered.
      </p>
      <br />
      <h5>1. PDF Array Buffer</h5>
      <p>
        The idea was that after drawing on an HTML canvas and a PDF, we would
        convert both of them into an array buffer and compare each byte in the
        arrays. Since the API returns a blob, we could use the arraybuffer
        function to convert it into a buffer, whereas for the HTML canvas we
        could get the buffer from the image data.
      </p>
      <br /> <br />
      <CopyBlock
        language="jsx"
        text={demo8}
        showLineNumbers={true}
        wrapLines={true}
        codeBlock
      />
      <br />
      <p>
        The problem with this approach is that even when the exact same canvas
        methods are called in the same order, the output array buffer from an
        HTML canvas is generated differently each time. Thus, it was impossible
        to compare array buffers with an HTML canvas, and for this reason, UI
        tests were considered instead.
      </p>
      <br />
      <h5>2. Pixelmatch with PDFJS</h5>
      <p>
        Pixelmatch is a JavaScript pixel-level image comparison library that can
        compare two canvases and return the number of mismatched pixels. Since
        the API returns a blob and not a canvas, we first have to convert it
        using pdfjs. To implement this, we can create an object URL from the
        blob returned by the API, get a document from the URL using pdfjs, then
        convert the document into a canvas.
      </p>
      <br /> <br />
      <CopyBlock
        language="jsx"
        text={demo9}
        showLineNumbers={true}
        wrapLines={true}
        codeBlock
      />
      <br />
      <p>
        Because pixelmatch can optionally create a diff canvas visually
        displaying the differences, it can be quite useful for debugging
        purposes. For example, for the tiger annotation from the initial
        baseline tests, the differences between the expected and actual image
        were not observed until pixelmatch’s diff canvas was used. Upon checking
        it, the fact that linewidth values less than one weren’t correctly being
        rendered by the API was realized.
      </p>
      <img src={pixelmatch} />
      <p>
        Ultimately, however, this approach was also axed because pdfjs could not
        correctly render hatch annotations.
      </p>
      <h5>3. URI Regression with Webviewer</h5>
      <p>
        For the above reasons, it was decided that Webviewer could substitute
        pdfjs since it also has the capability to convert a blob to a canvas and
        had no issues rendering hatch annotations. Additionally, instead of
        redundantly creating and drawing on an HTML canvas each time a test is
        run, we could generate a data URI from the API drawn canvas only once
        and save it in a file to form regression tests.
      </p>
      <br /> <br />
      <CopyBlock
        language="jsx"
        text={demo10}
        showLineNumbers={true}
        wrapLines={true}
        codeBlock
      />
      <br />
      <h4>Limitations of the API</h4>
      <br />
      <p>
        It is important to keep in mind the limitations of the API when using
        it.
      </p>
      <br />
      <p>1. Not all canvas methods are supported</p>
      <p>
        Examples of unsupported methods: setTransform, createPattern,
        setLineDash, etc
      </p>
      <br />
      <p>2. Some methods such as arc or bezier curve may be slightly off</p>
      <p>
        This is due to limitations in canvas2pdf’s current implementation of
        arc, bezier curve
      </p>
      <br />
      <p>3. Custom font families not supported</p>
      <p>
        Currently only supports PDFKit’s default fonts which are Times-Roman,
        Courier, and Helvetica
      </p>
      <br />
      <p>4. Linewidth less than one is not supported</p>
      <p>
        It is yet unknown why this is occurring, as when checked on Cosedit
        linewidth values of less than 1 are showing up as expected
      </p>
      <br />
      <p>5. Setting canvas properties is possible but not reading them</p>
      <p>
        This is important as Webviewer annotations, such as in Rectangle.ts,
        currently attempt to read the lineWidth property on canvas.
      </p>
      <br />
      <h5>Future Development</h5> <br />
      <p>The following actions are planned in the coming months:</p>
      <p>● Publish the API as a npm module for increased accessibility</p>
      <p>● Create a sample repo that uses the API npm module and Webviewer’s</p>
      <p>addCustomAppearance method</p>
      <br />
      <h5>Possible Todos in the Future</h5>
      <p>
        ● Increase support for canvas2pdf methods so that all or nearly all
        canvas methods are implemented
      </p>
      <p>● Allow custom font families to be used in PDFKit</p>
      <br />
      <br />
    </>
  );
};
