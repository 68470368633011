import React from "react";
import Project from "./Project";
import lonely from "../assets/lonelydemo.png";
import coursegpt from "../assets/coursegpt.png";
import apryse from "../assets/apryseUI.png";

import datavismask from "../assets/d3passion/vismask.png";
import qr from "../assets/qr2.png";
import signature from "../assets/signature.png";
import raster from "../assets/raster.jpeg";
import aws from "../assets/awsdiagram.png";
import gemini from "../assets/gemini.png";
import { ProjectIDs } from "../helpers/blogHelpers";

const projects = [
  {
    text: "AWS Cloud Resume Challenge",
    type: "Cloud",
    img: aws,
    link: `/projects/${ProjectIDs.CLOUDRESUME}`,
    active: true,
  },
  {
    text: "Storytelling Loneliness",
    type: "Data Vis",
    img: lonely,
    link: "/projects/loneliness",
    active: true,
  },
  {
    text: "Signatures Styles",
    type: "API",
    img: signature,
    link: "/projects/signatures",
    active: true,
  },
  {
    text: "Vector Appearances",
    type: "API",
    img: raster,
    link: "/projects/vector",
    active: true,
  },
  {
    text: "Apryse Responsive Design",
    type: "UI",
    img: apryse,
    link: `/projects/${ProjectIDs.APRYSEUI}`,
    active: true,
  },
  {
    text: "Experimenting with D3",
    type: "Data Vis",
    img: datavismask,
    link: `/projects/${ProjectIDs.D3}`,
    active: true,
  },
  {
    text: "SVG Animations",
    type: "UI",
    img: gemini,
    link: "/projects/svg",
    active: false,
  },
  {
    text: "Personalizing Education",
    type: "Fullstack",
    img: coursegpt,
    link: "/projects/coursegpt",
    active: false,
  },
  {
    text: "Custom tooling for nwPlus",
    type: "Fullstack",
    img: qr,
    link: "/projects/nwplus",
    active: false,
  },
];

const Projects = ({ projectRef }) => {
  return (
    <div className="gradientBorder py-24 my-16 text-left" ref={projectRef}>
      <div className="text-5xl md:text-6xl pl-16">Projects</div>
      <div className="text-xl pl-16 mt-8">
        A selection of my work over the years
      </div>
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-12 my-24 ">
        {projects.map(({ text, type, img, link, active }, i) => (
          <Project
            key={i}
            text={text}
            type={type}
            img={img}
            link={link}
            active={active}
          />
        ))}
      </div>
    </div>
  );
};

export default Projects;
