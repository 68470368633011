import { useEffect, useRef, useState } from "react";
import "./App.css";
import Spinner from "./component/Spinner";
import CanvasComponent from "./component/CanvasComponent";
import Landing from "./component/Landing";
import Skills from "./component/Skills";
import { Tooltip } from "react-tooltip";
import Playground from "./component/Playground";
import Projects from "./component/Projects";
import Experience from "./component/Experience";
import Footer from "./component/Footer";

const App = () => {
  const [loading, setLoading] = useState(false);
  const aboutRef = useRef(null);
  const skillsRef = useRef(null);
  const projectRef = useRef(null);
  const expRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const renderPage = () => {
    if (loading) {
      return <Spinner />;
    }
    return (
      <div>
        <div className="mx-auto w-full md:w-4/5 px-8 md:px-0">
          <Landing
            aboutRef={aboutRef}
            skillsRef={skillsRef}
            projectRef={projectRef}
            expRef={expRef}
          />
          <div className="text-neutral-600 text-4xl pt-16 mt-20 leading-[2.75rem]">
            Welcome to my playground
          </div>
          <div className="text-neutral-500 text-lg py-4 pb-8 leading-[2.75rem]">
            Move the cursor around
          </div>
          <Playground />

          <div className="pb-24 pt-20" ref={aboutRef}>
            <div className="text-neutral-500 text-lg py-4 leading-[2.75rem]">
              A little about me
            </div>
            <div className="text-2xl px-8 md:px-16 lg:px-32 pb-4 leading-9 m-auto enlarge">
              I'm a developer interested in solving impactful technical problems
              and engineering creative experiences. I am passionate about
              technology's applications for social good and building a better
              tomorrow. My previous work centered on <b>usability</b>,{" "}
              <b>performance</b>, and <b>accessibility</b>.
            </div>
          </div>
          <Experience expRef={expRef} />
          <Skills skillsRef={skillsRef} />
          <Projects projectRef={projectRef} />
        </div>
        <Footer />
        <Tooltip id="my-tooltip" />
        <CanvasComponent />
      </div>
    );
  };

  return <div className="App">{renderPage()}</div>;
};

export default App;
